<template>
  <div>
    <div class="upload">
      <div class="title">
        更新手机号-牌位号列表
      </div>
      <el-upload ref="upload" action="" :auto-upload="false" :on-change="onChange" :limit="1">
        <el-button type="primary" size="medium" style="width: 10cm">上传文件</el-button>
      </el-upload>
      <div class="tips">
        *上传成功后立即更新数据
      </div>
    </div>
  </div>
</template>

<script>
import xlsx from 'xlsx'

export default {
  name: "FileUpload",
  data() {
    return {
      data: []
    }
  },
  methods: {
    readFile: (file) => {
      return new Promise(resolve => {
        let reader = new FileReader()
        reader.readAsBinaryString(file)
        reader.onload = ev => {
          resolve(ev.target.result)
        }
      })
    },
    async onChange (file) {
      let dataBinary = await this.readFile(file.raw)
      let workBook = xlsx.read(dataBinary, {type: 'binary', cellDates: true})
      let sheetData = workBook.Sheets[workBook.SheetNames[0]];
      this.data = xlsx.utils.sheet_to_json(sheetData)
      let params = {
        memorialTabletList: this.data.map(item => ({
          phone: item['手机号'],
          memorialTablet: item['牌位号'],
        }))
      }
      console.log(params)
      let res = await this.$http.post( '/memorial-tablet/list', params)
      if (res.success) {
        this.$message.success('更新成功')
      }
    },
  }
}
</script>

<style scoped>
.upload {
  /*width: 10cm;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1cm 0;
}

.tips {
  font-size: 14px;
  margin-top: .5cm;
  color: #999;
}

.title {
  margin-bottom: .8cm;
  font-size: 18px;
  font-weight: bold;
}
</style>